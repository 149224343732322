import axios from 'axios';
import React from 'react';

import logo from "../img/logo_dark.svg";
import facebook from '../img/social/facebook.svg'
import footer from "../img/footer5_Footer2.png";
import instagram from '../img/social/instagram.svg'
import linkedin from '../img/social/linkedin.svg'
import youtube from '../img/social/youtube.svg'

// import privacidade from '../pdf/privacidade.pdf'

import "./Footer.scss";

const Footer = class extends React.Component {
  newsletterInput;

  submitNewsletter = (event) => {
    event.preventDefault();
    const email = this.newsletterInput.value;
    this.newsletterInput.value = '';
    const res = axios.post(`${process.env.NEWSLETTER_URL}`, { email });
    const msg = (res && res.status !== 200)
      ? 'E-mail cadastrado com sucesso!'
      : 'Não foi possível cadastrar seu e-mail!';
    alert(msg);
  }

  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter" style={{
        backgroundImage: `url(${footer})`,
      }}>
        <div className="footer__left">
          <img
            src={logo}
            alt="Logo"
            className="logo"
          />
        </div>
        <div className="footer__middle">
          <h4 className="title is-4 has-text-primary">ENTRE EM CONTATO</h4>
          <a href="mailto:contato@amigosdapoli.com.br">contato@amigosdapoli.com.br</a>
          <div className="footer__middle-buttons">
            <div>
              <a className="button is-link" href="https://doe.amigosdapoli.com.br">
                quero doar!
              </a>
            </div>
            <div>
              <br></br>
              <a href="http://privacidade.amigosdapoli.com.br" target="_blank" rel="noreferrer" className="button is-link">
                privacidade
              </a>
            </div>
          </div>
        </div>
        <div className="footer__right">
          <h4 className="title is-4 has-text-primary">ASSINE NOSSA NEWSLETTER</h4>
          <div className="newsletter">
            <form onSubmit={this.submitNewsletter}>
              <input
                ref={node => (this.newsletterInput = node)}
                type="email"
                name="email"
                placeholder="Seu e-mail" />
              <button className="button is-link" type="submit">assinar</button>
            </form>
          </div>
          <div className="social-links">
            <a className="social" title="facebook" href="https://www.facebook.com/AmigosdaPoli/">
              <img src={facebook} alt="Facebook" />
            </a>
            <a className="social" title="instagram" href="https://www.instagram.com/amigosdapoli/?hl=en">
              <img src={instagram} alt="Instagram" />
            </a>
            <a className="social" title="linkedin" href="https://www.linkedin.com/company/amigos-da-poli/">
              <img src={linkedin} alt="linkedin" />
            </a>
            <a className="social" title="youtube" href="https://www.youtube.com/channel/UC9vEhMLL-qj-Q7619CC-q2Q">
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer

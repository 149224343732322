import React from 'react';
import { Link } from 'gatsby';
import logo from '../img/logo.png';
import './Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      }
    );
  };

  render() {
    return (
      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <div className="container">
          <div>
            <div className="navbar-item-img">
              <Link to="/" title="Logo">
                <img className="navbar-logo" src={logo} alt="Logo Amigos da Poli" />
              </Link>
            </div>
            <div className={`navbar-burger burger ${this.state.navBarActiveClass} show-burger`} data-target="navMenu" onClick={() => this.toggleHamburger()}>
              <span />
              <span />
              <span />
            </div>
          </div>
          {/* Hamburger menu */}
          {/* eslint-disable */}
          <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/">
                home
              </Link>
              <a className='dropdown is-right is-hoverable'>
                <div class="navbar-item dropdown-trigger" aria-haspopup="true" aria-controls="dropdown-menu">
                  impacto
                  <div className='navbar-icon'>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </div>
                </div>
                <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                  <div className='dropdown-content'>
                    <Link to="/editais" className='dropdown-item'>Editais</Link>
                    <a href='https://www.centrodecarreiradapoli.com.br/' target='_blank' className='dropdown-item'>Centro de Carreira</a>
                  </div>
                </div>
              </a>
              <Link className="navbar-item" to="/about">
                sobre nós
              </Link>
              <Link className="navbar-item" to="/donors">
                doadores
              </Link>
              {/* <Link className="navbar-item" to="/entities">
                mês de doar
              </Link> */}
              <Link className="navbar-item" to="/blog">
                blog
              </Link>

              <div className="column navbar-item-align has-text-centered">
                <Link className="navbar-item-donate" to="https://doe.amigosdapoli.com.br">
                  faça sua doação
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;

import { withPrefix } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import useSiteMetadata from './SiteMetadata'

import './all.scss'

const TemplateWrapper = ({ children }) => {
  const { title, description, isProduction } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="google-site-verification" content="CXzhYHlkSPCX0XMLbSY3QWCcwFtV_tq8-QN9gWCI4qQ" />

        {/* <!-- Início do aviso de consentimento de cookies OneTrust para amigosdapoli.com.br --> */}
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script={`527d44ee-6624-48c6-b1ac-4deb5787946c${isProduction?'':'-test'}`} >
        </script>
        <script type="text/javascript">
          function OptanonWrapper() { }
        </script>
        {/* <!-- Final do aviso de consentimento de cookies OneTrust para amigosdapoli.com.br --> */}

        <meta name="kdt:page" content={title}></meta>
        <script type="text/javascript" src="/konduto.js"></script>
        <script type="text/javascript" src="/kondutoId.js"></script>
        {/* <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        /> */}
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/Favicon-Chevron2.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-Chevron2.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />

        { /*Hotjar*/ }
        <script>{"(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2609604,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"}</script>

        { /*Google Analytics*/ }
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-LQ1RBZLY1B"></script>
        <script>{"window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-LQ1RBZLY1B');"}</script>

        { /*RD Station*/ }
        <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/c706ff3a-e732-48b5-9e43-d698ef917370-loader.js" ></script>
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
